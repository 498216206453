import React from "react"
import Slider from "react-slick"
import { Trans } from "gatsby-plugin-react-i18next"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
}

const Recommendation = () => {
  return (
    <Slider {...settings}>
      <div>
        <p>
          <Trans>feedback.content-1</Trans>
        </p>
      </div>
      <div>
        <p>
          <Trans>feedback.content-2</Trans>
        </p>
      </div>
      <div>
        <p>
          <Trans>feedback.content-3</Trans>
        </p>
      </div>
    </Slider>
  )
}
export default Recommendation
