import React from "react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import logoZalando from "../images/home/logo-zalando.png"
import logoCyberport from "../images/home/logo-cyberport.png"
import logoBmw from "../images/home/logo-bmw.png"
import logoDaimler from "../images/home/logo-daimler.png"
import logoShell from "../images/home/logo-shell.png"

import step1 from "../images/home/step-1.png"
import step2 from "../images/home/step-2.png"
import step3 from "../images/home/step-3.png"
import step4 from "../images/home/step-4.png"
import step5 from "../images/home/step-5.png"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import Recommendation from "../components/recommendation"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <Layout variant="home">
      <SEO title="Home" />

      <article className="home" id="start-now">
        <header className="home__header">
          <div className="home__header-container">
            <div className="home__banner">
              <h1 className="heading heading--md">
                <small>{t("hero.header-small")}</small>
                <strong>{t("hero.header")}</strong>
                <span className="benefit-info">
                  <Trans>hero.second-header</Trans>
                </span>
              </h1>
              <a href="#home-features" className="home__scroll-down">
                <FontAwesomeIcon icon={faChevronDown} />
              </a>
            </div>
            <div className="home__actions">
              <div>
                <h2>
                  <Trans>hero.right-header</Trans>{" "}
                  <strong>
                    <Trans>hero.right-header-green</Trans>
                  </strong>
                </h2>
                <p className="paragraph paragraph--md">
                  <Trans>hero.right-paragraph-1</Trans>
                </p>

                <div className="home__actions-button">
                  <a
                    href="https://route-optimizer.app/users/sign_up"
                    className="btn btn--md btn--primary"
                  >
                    <Trans>start-now</Trans>
                  </a>
                </div>

                <div className="home__customers" id="home-customers-section">
                  <h3>
                    <Trans>customers-header</Trans>
                  </h3>
                  <ul className="home__logotypes">
                    <li>
                      <figure>
                        <img src={logoZalando} alt="Zalando" />
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={logoCyberport} alt="Cyberport" />
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={logoBmw} alt="BMW" />
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={logoDaimler} alt="Daimler" />
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={logoShell} alt="Shell" />
                      </figure>
                    </li>
                  </ul>
                </div>

                <p className="paragraph paragraph--md">
                  <Trans>hero.right-paragraph-2</Trans>
                </p>
              </div>
            </div>
          </div>
        </header>

        <header
          id="home-features"
          className="home__steps-header article-header text--center"
        >
          <h2 className="heading heading--md">
            <Trans>home-steps.header</Trans>
          </h2>
          <p className="paragraph paragraph--lg">
            <Trans>home-steps.paragraph</Trans>
          </p>
        </header>

        <ol className="home__steps">
          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={step1} alt="Get your API key for FREE" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-1-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-1-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={step2} alt="Send a test call" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-2-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-2-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={step3} alt="Prepare for the callback" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-3-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-3-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={step4} alt="Cyberport" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-4-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-4-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={step5} alt="Cyberport" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-5-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-5-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <section className="home__final-word">
              <h3 className="heading heading--sm">
                <Trans>home-steps.summary</Trans>
              </h3>

              <a
                href="https://route-optimizer.app/users/sign_up"
                className="btn btn--md btn--primary"
              >
                <Trans>start-now</Trans>
              </a>
            </section>
          </li>
        </ol>

        <div className="home__check-out text--center">
          <p>
            <Trans>check-justgo.paragraph</Trans>
          </p>
          <strong>
            <Trans>check-justgo.paragraph-strong</Trans>
            <a className="default-link" href="https://justgo.tiramizoo.com/">
              JustGo
            </a>
            <Trans>check-justgo.paragraph-strong-end</Trans>
          </strong>
        </div>

        <section className="slick-carousel">
          <h2 className="heading heading--md text--center">
            <Trans>feedback.header</Trans>
          </h2>
          <Recommendation />
        </section>
      </article>
    </Layout>
  )
}

export default IndexPage
